import React from "react";

// Helper function to detect and render URLs
function renderTextWithLinks(text) {
  if (typeof text !== "string") {
    // Ensure `text` is a string or return a fallback
    return "";
  }

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);

  return parts.map((part, index) =>
    urlRegex.test(part) ? (
      <a
        key={index}
        href={part}
        target="_blank"
        rel="noopener noreferrer"
        className="!underline"
      >
        {part}
      </a>
    ) : (
      part
    )
  );
}

function SimpleMessage({ message }) {
  const text = message?.text || ""; // Fallback to an empty string if `message.text` is undefined
  return <div>{renderTextWithLinks(text)}</div>;
}

export default SimpleMessage;
