import React from "react";

function LoadingMessage({ message }) {
  return <div
      style={{
        width: 100,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
  >
    <div className="dot-pulse"></div>
  </div>
}

export default LoadingMessage;
