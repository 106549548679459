/*global chrome*/
import "./App.css";
import MainPage from "./Components/MainPage";
import Transcripts from "./Components/Transcripts/index";
import Layout from "./Components/Layout";
import {ThemeProvider} from "next-themes";
import {useEffect, useMemo, useRef, useState} from "react";
import Login from "./Components/Auth";
import Loading from "./Components/Loading";
import {
    generateCode,
    getActions,
    getAuthFromToken,
    getChatHistory,
    getNextMessage,
    SendMessage
} from "./utils/Chat/chatIntegrationApi";
import Pusher from "pusher-js";
import {toast} from "sonner";

export function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    ), [ref])


    useEffect(() => {
        observer.observe(ref.current)
        return () => observer.disconnect()
    }, [])

    return isIntersecting
}

function App() {

    let agent = process.env.REACT_APP_AGENT_ID || "";
    const ref = useRef(null)
    const isVisible = useOnScreen(ref)


    useEffect(() => {
        if(isVisible){

        }
    }, [isVisible]);

    const [showFloatingWindow, setShowFloatingWindow] = useState(false);
    const [showMainScreen, setShowMainScreen] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);

    const [isEnrolled, setIsEnrolled] = useState(true);

    //chat Handlers
    const [sendMessageLoading, setSendMessageLoading] = useState(true);
    const [channelId, setChannelId] = useState("");
    const [input, setInput] = useState("");
    const [file, setFile] = useState(null);
    const [userMessages, setUserMessages] = useState([]);

    const channel = new BroadcastChannel("build-up-extension-channel");
    const [loading, setLoading] = useState(false);

    const [waitingForAgent, setWaitingForAgent] = useState(true);
    const [authChanged, setAuthChanged] = useState(false)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const buildupToken = urlParams?.get("token");


        if (buildupToken) {
            setLoading(true);
            getAuthTokenFromDB(buildupToken)
                .then((accessToken) => {
                    if (accessToken) {
                        localStorage.setItem("token", accessToken);
                        setAuthChanged(Date.now);
                        const iframe = document.getElementById("build-up-extension-iframe");
                        iframe.contentWindow.postMessage(
                            {
                                type: "AUTH_TOKEN",
                                token: accessToken,
                            },
                            "*"
                        );
                        window.postMessage(
                            {
                                type: "AUTH_TOKEN",
                                token: accessToken,
                            },
                            "*"
                        );
                        setIsAuthorized(true);

                        setTimeout(() => {
                            window.close();
                        }, 2000);
                    }
                })
                .catch((error) => {

                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    const sendMessageToContentScript = (message, callBack) => {
        chrome?.tabs?.query({active: true, currentWindow: true}, function (tabs) {
            chrome.tabs.sendMessage(tabs[0].id, message, function (response) {
                if (callBack) {
                    callBack(response);
                }
            });
        });
    }

    useEffect(() => {
        if (!isAuthorized) {
            return;
        }
        sendMessageToContentScript({detail: {key: 'getUrl'}}, (url) => {
            getActions({url}).then((response) => {
                sendMessageToContentScript({detail: {key: 'actions', value: response.data}}, (actions) => {

                });
            }).catch(e => {

            });
        });

    }, [isAuthorized])


    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            setIsAuthorized(true);
        } else {
            setIsAuthorized(false);
        }
    }, [authChanged]);


    useEffect(() => {
        window.addEventListener('message', event => {
            if (event.data.type === 'AUTH_CHANGED') {
                setAuthChanged(!authChanged);
            }
        });
    }, []);


    useEffect(() => {

        const checkLocalStorage = () => {
            const showFloatingScreenStored =
                localStorage.getItem("showFloatingScreen") === "true";
            if (showFloatingScreenStored !== showFloatingWindow)
                setShowFloatingWindow(showFloatingScreenStored);
        };

        checkLocalStorage();
        //const intervalId = setInterval(checkLocalStorage, 100);

        //return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (isAuthorized) {
            getChannelID();
        }
    }, [isAuthorized]);

    useEffect(() => {
        console.log({isVisible, channelId});
        if (channelId && (isVisible || agent !== 'extension')) {
            const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_TOKEN, {
                cluster: "us2",
            });

            const channel = pusher.subscribe(channelId);

            getChatHistory().then((response) => {
                setUserMessages(response?.data);
                setShowMainScreen(false);
            });

            channel.bind("message", (data) => {

                //Check if message already exists
                if(userMessages.find(message => (data.id && message.id === data.id))){
                    return;
                }

                setUserMessages((prevMessages) => [...prevMessages, data]);
            });

            return () => {
                pusher.unsubscribe(channelId);
            };
        }
    }, [channelId, isVisible]);

    // get channel Id
    const getChannelID = async () => {
        if (!isAuthorized) return;
        let channelId = "";
        setSendMessageLoading(true);
        try {
            const res = await generateCode({});
            channelId = res?.data?.id;
            setChannelId((channelId || 0)?.toString());
        } catch (error) {
            setIsEnrolled(false);
        } finally {
            setSendMessageLoading(false);
        }

        return channelId;
    };

    const sendMessage = async () => {
        // debugger;
        if (
            (input && input?.trim().length > 0 && !sendMessageLoading) ||
            (file && !sendMessageLoading)
        ) {
            setSendMessageLoading(true);
            setShowMainScreen(false);
            let userObj = {
                type: "text",
                text: input,
            };
            if (file) {
                userObj = {
                    type: "file",
                    file: file,
                    text: input
                };
            }

            try {
                setInput("");
                // setFile(null);
                await SendMessage(userObj);
            } catch (error) {
                toast.warning("Error while sending message");
            }

            setSendMessageLoading(false);
        }
    };

    useEffect(() => {
        setWaitingForAgent(true);
        if (userMessages.length === 0) return;

        let url = "";

        if (agent === "extension") {

            const urlParams = new URLSearchParams(window.location.search);
            url = urlParams?.get("url");
        }

        getNextMessage({url, agent})
            .then((response) => {
                setWaitingForAgent(response.data.waitingForAgent);
            })
            .catch((error) => {
            });
    }, [userMessages]);

    const getAuthTokenFromDB = async (token) => {
        const {data} = await getAuthFromToken({token});
        return data?.token;
    };

    if (loading) {
        return (
            <ThemeProvider attribute="class">
                <Loading/>
            </ThemeProvider>
        );
    }

    if(!isEnrolled){
        return (
            <ThemeProvider attribute="class">
                <Layout
                    setIsAuthorized={setIsAuthorized}
                    showFloatingWindow={showFloatingWindow}
                    input={input}
                    sendMessageLoading={sendMessageLoading}
                    setShowFloatingWindow={setShowFloatingWindow}
                    setShowMainScreen={setShowMainScreen}
                    sendMessage={sendMessage}
                    setInput={setInput}
                    setFile={setFile}
                >
                    Looks like you are not enrolled in the course. Please enroll in the course to use this extension.
                </Layout>
            </ThemeProvider>
        );
    }
    return (
        <div ref={ref}>
            <ThemeProvider attribute="class">
                {isAuthorized ? (
                    <Layout
                        setIsAuthorized={setIsAuthorized}
                        showFloatingWindow={showFloatingWindow}
                        input={input}
                        sendMessageLoading={sendMessageLoading}
                        setShowFloatingWindow={setShowFloatingWindow}
                        setShowMainScreen={setShowMainScreen}
                        sendMessage={sendMessage}
                        setInput={setInput}
                        setFile={setFile}
                    >
                        {showMainScreen ? (
                            <MainPage setShowMainScreen={setShowMainScreen}/>
                        ) : (
                            <Transcripts
                                userMessages={userMessages}
                                waitingForAgent={waitingForAgent}
                            />
                        )}
                    </Layout>
                ) : (
                    <Login setAuthChanged={setAuthChanged} authChanged={authChanged}/>
                )}
            </ThemeProvider>
        </div>
    );
}

export default App;
