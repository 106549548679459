import { useEffect, useState } from "react";
import { Textarea } from "../../ui/textarea";
import { Button } from "../../ui/button";
import "./index.scss";

import { ReactComponent as SaveIcon } from "../../Assets/icons/save-icon.svg";
import { ReactComponent as ShareIcon } from "../../Assets/icons/share-icon.svg";
import { ReactComponent as ClipIcon } from "../../Assets/icons/clip-icon.svg";
import { ReactComponent as ImageIcon } from "../../Assets/icons/image-icon.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icons/close-outline.svg";
import AttachFile from "./comps/AttachFile";
import ImageUpload from "./comps/ImageUpload";
import UploadedFile from "./comps/UploadedFile";
import { Loader } from "lucide-react";

const InputBase = ({
  sendMessage,
  setInput,
  input,
  sendMessageLoading,
  setFile,
}) => {
  const [filePopup, setFilePopup] = useState(false);
  const [imagePopup, setImagePopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    setFile(selectedFile);

    sendMessage();
    setInput("");
    setFilePopup(false);
  }, [selectedFile]);
  return (
    <div className="build-up-chat-screen-footer">
      {/* {file ? (
                <UploadedFile />
            ) : null} */}
      <div className={`build-up-input-section`}>
        <SaveIcon width={24} height={24} />
        <div className={`build-up-input-container flex-1`}>
          <Textarea
            placeholder="Start Chatting Now"
            className="build-up-merlinExt-custom-scrollbar"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e?.key === "Enter" && !e?.shiftKey) {
                e.preventDefault();
                sendMessage();
              }
            }}
          />
          {/* <input/> */}
          <div className="input-buttons">
            {sendMessageLoading && <Loader size={16} />}
            {error ? (
              <Button
                variant="ghost"
                size="icon"
                className="icon-btn"
                onClick={() => setFile("")}
                style={{ marginRight: 8 }}
              >
                <CloseIcon width={21} height={21} />
              </Button>
            ) : (
              <Button
                id={"clip-btn"}
                variant="ghost"
                size="icon"
                className="icon-btn"
                onClick={() => setFilePopup(!filePopup)}
              >
                <ClipIcon id={"clip-btn"} width={21} height={21} />
              </Button>
            )}
            <Button
              id={"image-btn"}
              variant="ghost"
              size="icon"
              className="icon-btn"
              onClick={() => setImagePopup(!imagePopup)}
            >
              <ImageIcon id={"image-btn"} width={21} height={21} />
            </Button>
          </div>
        </div>
        <ShareIcon
          width={24}
          height={24}
          onClick={sendMessage}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="build-up-footer-text">
        <p>Copyright 2024 BuildUp Foundation</p>
      </div>

      {/* Modals*/}
      <AttachFile
        open={filePopup}
        handleClose={() => setFilePopup(false)}
        uploadFile={setSelectedFile}
        setError={setError}
        setInput={setInput}
      />
      <ImageUpload
        open={imagePopup}
        handleClose={() => setImagePopup(false)}
        uploadFile={setSelectedFile}
      />
    </div>
  );
};

export default InputBase;
