import React from "react";

// Helper function to detect and enhance plain text with links
function renderPlainTextWithLinks(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);

  return parts.map((part, index) =>
    urlRegex.test(part) ? (
      <a
        key={index}
        href={part}
        target="_blank"
        rel="noopener noreferrer"
        class="underline"
      >
        {part}
      </a>
    ) : (
      part
    )
  );
}

// Helper function to enhance existing HTML links
function enhanceHTMLLinks(html) {
  return html.replace(
    /<a\s+([^>]*?href="[^"]*")/g,
    `<a $1 target="_blank" rel="noopener noreferrer" class="underline"`
  );
}

function HTMLMessage({ message }) {
  const content = message?.payload?.html || "";

  if (typeof content !== "string") {
    return null; // Handle non-string cases gracefully
  }

  if (!/<\/?[a-z][\s\S]*>/i.test(content)) {
    // Plain text case: render with links
    return <div>{renderPlainTextWithLinks(content)}</div>;
  }

  // HTML case: enhance links and render safely
  const enhancedHTML = enhanceHTMLLinks(content);

  return <div dangerouslySetInnerHTML={{ __html: enhancedHTML }} />;
}

export default HTMLMessage;
